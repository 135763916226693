/* requires:
polyfill.js
*/

// using host value to pull pricing for all products on a website
// API call will always need a host value
// host and testPrice values are not case sensitive
// groupId and tags value are case sensitive
// https://dlapi.certus.com/Products/api/v1/Public/GetProductsBy?host=Sandbox&groupId=Sandbox%20group&testPrice=A

// should the build pull and hardcode the prices blurring them for a few seconds?
// after the page loads, the api will be called again with any price modifier triggering price display
// use a combination of getElements* and querySelector* to improve performance
// used getElements* to get all price element, then select which one to update to add price display

const pricing = {
  init: function () {
    const productAPIUrl = 'product_api_url_replaced_during_build';
    const unpublishedData = 'product_unpublished_replaced_during_build';
    const fullSiteBuild = 'full_site_build_replaced_during_build';
    const defaultHost = 'default_host_replaced_during_build';
    const defaultGroupId = 'default_group_replaced_during_build';
    const queryTestPrice = pricing.getQueryString('tp') || pricing.readCookie('_up4tp') || null;
    const queryGroupId =
      pricing.getQueryString('gid') ||
      pricing.getQueryString('lgr') || // checking for LGR because some LGRs of NYSC are the group IDs
      pricing.readCookie('_up4gid') ||
      defaultGroupId ||
      null;
    const queryCoupon = pricing.getQueryString('cc') || pricing.readCookie('_up4cc') || null;

    // setting cross-domain cookie for the cart
    pricing.setCookie('unpublished', unpublishedData === 'true' ? 1 : 0);

    // fetch products data from API
    const fetchProducts = (useDefault) => {
      const testPriceQuery = queryTestPrice && !useDefault?.testPrice ? `&testPrice=${queryTestPrice}` : '';
      const groupIdQuery =
        queryGroupId && !useDefault?.groupId
          ? `&groupId=${queryGroupId}`
          : useDefault?.groupId && defaultGroupId !== ''
            ? `&groupId=${defaultGroupId}`
            : '';
      const cookieCoupon = pricing.readCookie('_up4cc');
      const couponQuery =
        queryCoupon && !useDefault?.coupon
          ? `&couponcode=${queryCoupon}`
          : cookieCoupon
            ? `&couponcode=${cookieCoupon}`
            : '';
      const unpublishedQuery = `&unpublished=${unpublishedData}`;
      const fullSiteBuildQuery = `&fullSiteBuild=${fullSiteBuild}`;

      // API url
      const productAPI = `${productAPIUrl}/GetProductsBy/?host=${defaultHost}${testPriceQuery}${groupIdQuery}${couponQuery}${unpublishedQuery}${fullSiteBuildQuery}`;

      // calling the API here
      fetch(productAPI)
        .then((res) => {
          if (res.status !== 200 && res.status < 500) {
            console.log('API Status Code: ' + res.status);
            return res.json();
          }
          if (res.status >= 500) {
            console.log('API Status Code: ' + res.status);
            return;
          }
          return res.json();
        }) // making sure that the response is in JSON format
        .then((res) => {
          if (!res?.errors) {
            let isGroupIdGood = true;
            let isCouponGood = true;

            // console.log(res);
            // if queryTestPrice is used on any product, set it as a cookie
            const isTestPriceApplied = res.some(
              (product) => product?.pricing?.typePricing?.toLowerCase() === queryTestPrice?.toLowerCase()
            );
            if (queryTestPrice && isTestPriceApplied) {
              pricing.setCookie('_up4tp', queryTestPrice?.toLowerCase(), 30);
            }
            // setting the test price query string as a session cookie
            // since it is applied to one or more of the main products
            // ONLY if the value is one of A/B/C
            else if (queryTestPrice && !useDefault?.testPrice) {
              pricing.setCookie('_up4tp', queryTestPrice?.toLowerCase());
            }

            // if queryGroupId or defaultGroupId is used on any product, set it as a cookie
            const isGroupIdApplied = res.some(
              (product) =>
                product?.pricing?.groupId?.toLowerCase() === queryGroupId?.toLowerCase() ||
                product?.pricing?.groupId?.toLowerCase() === defaultGroupId?.toLowerCase()
            );

            if ((queryGroupId || useDefault?.groupId) && isGroupIdApplied) {
              if (queryGroupId && !useDefault?.groupId) {
                pricing.setCookie('_up4gid', queryGroupId?.toLowerCase(), 30);
              }
              // set default groupId
              else if (useDefault?.groupId && defaultGroupId !== '') {
                pricing.setCookie('_up4gid', defaultGroupId?.toLowerCase(), 30);
              }

              isGroupIdGood = true;
            }
            // if queryGroupId is not aplied and there's a default groupId refetch pricing using cookie
            else if (!useDefault?.groupId && defaultGroupId !== '') {
              const useDefault = {
                groupId: true,
              };

              isGroupIdGood = false;

              console.log('Calling API with default groupId!');
              fetchProducts(useDefault);
              // pricing.deleteCookie('_up4cc');
            }

            // if queryCoupon is valid/used on any product, set it as a cookie
            const isCouponApplied = res.some((product) => product?.pricing?.discountSource === 'Coupon');
            if (queryCoupon && isCouponApplied && !useDefault?.coupon) {
              console.log('Setting cookie coupon!');
              pricing.setCookie('_up4cc', queryCoupon?.toLowerCase(), 30);

              isCouponGood = true;
            }
            // if queryCoupon is not valid and there's a valid coupon in the cookie refetch pricing using cookie
            else if (!useDefault?.coupon && cookieCoupon) {
              const useDefault = {
                coupon: true,
              };

              isCouponGood = false;

              console.log('Calling API with cookie coupon!');
              fetchProducts(useDefault);
              // pricing.deleteCookie('_up4cc');
            }

            // display prices and store product pricing details
            if (isGroupIdGood && isCouponGood) {
              pricing.displayPrice(res);
              pricing.storeProductData(res);
            }
          }
          // if response has errors call pricing API with defaults
          else {
            // console.log(res?.errors);
            const useDefault = {
              testPrice: res?.errors?.testPrice ? true : false,
              groupId: res?.errors?.groupId ? true : false,
            };
            if (res?.errors?.testPrice) {
              pricing.deleteCookie('_up4tp');
              console.log('Calling API with default testPrice!');
            }
            if (res?.errors?.groupId) {
              pricing.deleteCookie('_up4gid');
              console.log('Calling API with default groupId!');
            }
            fetchProducts(useDefault);
          }
        })
        .catch(function (err) {
          console.error('API call failed', err);
        });
    };

    // check if product data is in storage
    const productsData = sessionStorage.getItem('productsData') && JSON.parse(sessionStorage.getItem('productsData'));

    if (productsData) {
      // use stored product data and update it by calling the API
      pricing.displayPrice(productsData);
      fetchProducts();
    } else {
      // call the API
      fetchProducts();
    }
  },

  displayPrice: function (products) {
    // find all price display elements
    // check if data-id value exist on the products array
    // grab pricing for that product
    // render the values in the DOM

    // for displaying the styled prices
    const priceStyledCollection = [...document.getElementsByClassName('price')];
    for (let priceElement of priceStyledCollection) {
      const id = priceElement.dataset.pid;
      if (id) {
        const product = products.find((product) => product.id.toLowerCase() === id.toLowerCase());
        const priceObj = product?.pricing;
        const amount = priceObj?.discountAmount
          ? (priceObj?.price - priceObj?.discountAmount).toFixed(2).toString()
          : priceObj?.price?.toFixed(2).toString();
        const amountDollars = amount ? amount.split('.')[0] : null;
        const amountCents = amount ? (amount.split('.')[1] ? amount.split('.')[1] : '00') : null;
        priceElement.getElementsByClassName('dollars')[0].innerHTML = amountDollars;
        priceElement.getElementsByClassName('cents')[0].innerHTML = amountCents;
        // if cents is equal to 00 display nothing
        amountCents === '00'
          ? priceElement.getElementsByClassName('cents')[0].classList.add('display-none')
          : priceElement.getElementsByClassName('cents')[0].classList.remove('display-none');
        priceElement.classList.add('shown');
      }
    }

    // for displaying the unstyled prices
    const priceDisplayCollection = [...document.getElementsByClassName('price-display')];
    for (let priceElement of priceDisplayCollection) {
      const id = priceElement.dataset.pid;
      if (id) {
        const product = products.find((product) => product.id.toLowerCase() === id.toLowerCase());
        const priceObj = product?.pricing;
        // if there's priceAfterDiscount display priceAfterDiscount else display the price
        const amount =
          '$' +
          (priceObj?.discountAmount
            ? (priceObj?.price - priceObj?.discountAmount).toFixed(2).toString()
            : priceObj?.price?.toFixed(2).toString()
          ).replace('.00', '');
        priceElement.innerHTML = amount;
      }
    }

    // for displaying the the strikethrough prices
    const priceRegularCollection = [...document.getElementsByClassName('regular-price')];
    for (let priceElement of priceRegularCollection) {
      const id = priceElement.dataset.pid;
      if (id) {
        const product = products.find((product) => product.id.toLowerCase() === id.toLowerCase());
        const priceObj = product?.pricing;
        const amount = '$' + priceObj?.price?.toFixed(2).toString().replace('.00', '');
        priceElement.innerHTML = amount;
        // if there's no priceAfterDiscount display nothing
        priceElement.classList.remove('display-none');
        priceElement.closest('.regular-price-display')?.classList.remove('display-none');
        !priceObj?.discountAmount && priceElement.classList.add('display-none');
        !priceObj?.discountAmount && priceElement.closest('.regular-price-display')?.classList.add('display-none');
      }
    }

    // for displaying the amount of savings in case we need to
    const savingsAmountCollection = [...document.getElementsByClassName('savings-amount')];
    for (let priceElement of savingsAmountCollection) {
      const id = priceElement.dataset.pid;
      if (id) {
        const product = products.find((product) => product.id.toLowerCase() === id.toLowerCase());
        const priceObj = product?.pricing;
        const amount = '$' + priceObj?.discountAmount?.toFixed(2).toString().replace('.00', '');
        priceElement.innerHTML = amount;
        // if there's no discountAmount display nothing
        priceElement.classList.remove('display-none');
        priceElement.closest('.savings-display').classList.remove('display-none');
        !priceObj?.discountAmount && priceElement.classList.add('display-none');
        !priceObj?.discountAmount && priceElement.closest('.savings-display').classList.add('display-none');
      }
    }
  },

  storeProductData: function (products) {
    const productsArr = [];
    for (let product of products) {
      const productObj = {
        id: product.id,
        name: product.name,
        description: product.description,
        productCategory: {
          subCategoryName: product.productCategory?.subCategoryName || null,
          categoryName: product.productCategory?.categoryName || null,
          verticalName: product.productCategory?.verticalName || null,
        },
        tags: product.tags ? [...product.tags] : [],
        pricing: {
          price: product.pricing?.price,
          discountAmount: product.pricing?.discountAmount,
          priceAfterDiscount:
            product.pricing?.discountAmount > 0
              ? Math.round((product.pricing.price - product.pricing.discountAmount) * 100) / 100
              : product.pricing?.price,
          discountSource: product.pricing?.discountSource,
          typePricing: product.pricing?.typePricing,
          agencyFee: product.pricing?.agencyFee,
          agencyFeeName: product.pricing?.agencyFeeName,
          groupId: product.pricing?.groupId,
          coupon: product.pricing.discountSource === 'Coupon' ? pricing.readCookie('_up4cc') : null, // manually added
        },
        productBulkTiers: product.productBulkTiers,
      };
      productsArr.push(productObj);
    }
    sessionStorage.setItem('productsData', JSON.stringify(productsArr));
  },

  readCookie: function (name) {
    let nameEQ = encodeURI(name) + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return decodeURI(c.substring(nameEQ.length, c.length));
    }
    return null;
  },

  setCookie: function (name, value, days) {
    let expires = '';
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toGMTString();
    }
    const domain =
      location.hostname == 'localhost'
        ? ''
        : ';domain=.' + location.hostname.split('.').reverse()[1] + '.' + location.hostname.split('.').reverse()[0];
    const security = location.hostname == 'localhost' ? '' : ';SameSite=None; Secure';
    document.cookie = name + '=' + value + expires + ';path=/' + domain + security;
  },

  deleteCookie: function (name) {
    const location = window.location;
    const domain =
      location.hostname === 'localhost'
        ? ''
        : ';domain=.' + location.hostname.split('.').reverse()[1] + '.' + location.hostname.split('.').reverse()[0];
    const security = location.hostname === 'localhost' ? '' : ';SameSite=None; Secure';
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/' + domain + security;
  },

  getQueryString: function (name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  },

  updateQueryString: function (uri, key, value) {
    let re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
    let separator = uri.indexOf('?') !== -1 ? '&' : '?';
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + '=' + value + '$2');
    } else {
      return uri + separator + key + '=' + value;
    }
  },
};

pricing.init();
